import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useGlobalPageState } from '@kaliber/use-global-page-state'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getDatabase, push, update, ref, serverTimestamp, get } from 'firebase/database'
import { ref as storageRef, getStorage, uploadBytes } from 'firebase/storage'
import { useForm, object, useFormField, snapshot } from '@kaliber/forms'
import { useClientConfig } from '/machinery/ClientConfig'
import { maxLength, optional } from '@kaliber/forms/validation'
import { useElementSize } from '@kaliber/use-element-size'
import { useReportError } from '/machinery/ReportError'
import { getGoogleAnalyticsId } from '/machinery/getGoogleAnalyticsId'
import { useFirebaseApp } from '/machinery/FirebaseAppProvider'
import { useTranslate } from '/machinery/I18n'
import { fileSize, fileExtension, checked, linkedInUrl, email, required, noEmailAccents } from '/machinery/customValidation'
import { pushToDataLayer, mapJobForDataLayer } from '/machinery/tracking/pushToDataLayer'
import { FormFieldsWithSteps, FormFields } from '/features/buildingBlocks/FormFields'
import { SubheadingSm } from '/features/buildingBlocks/Subheading'
import { HeadingLg } from '/features/buildingBlocks/Heading'
import { TextSm } from '/features/buildingBlocks/Text'
import { Button } from '/features/buildingBlocks/Button'
import { BreathingCircles } from '/features/pageOnly/screensaver/BreathingCircles'
import { useOpenScreensaver } from '/features/pageOnly/screensaver/screenSaverControls'
import { routeMap } from '/routeMap'
import { LinkUnderline } from './Link'
import { useLocationMatch } from '@kaliber/routing'
import { mapValues } from '/machinery/mapValues'

import cssStyleContext from '/cssGlobal/style-context.css'
import styles from './Form.css'

// When changing filesize also change the firebase storage rules to match this (all environments)
const maxFileSize = 2 * 1024 * 1024 // 2 MB

const unpublishedJobMessage = 'unpublished-job-message'
const newRequiredFieldMessage = 'new-required-field-message'
const optionalFieldRequiredMessage = 'optional-field-required-message'
const messages = [unpublishedJobMessage, newRequiredFieldMessage, optionalFieldRequiredMessage]

const validationPerFieldType = {
  INPUT_TEXT: [maxLength(128)],
  TEXTAREA: [maxLength(3600)],
}

export function Form({ job, title, enablePortfolioField, utm, isEmployee }) {
  const trackedBeginCheckoutRef = React.useRef(null)
  const formRef = React.useRef(null)
  const { __ } = useTranslate()
  const firebaseApp = useFirebaseApp()
  const reportError = useReportError()
  const { acceptedFileTypes, ga4Id } = useClientConfig()
  const client = useQueryClient()
  const resumeFieldRequired = job.locationType === 'offices'

  const extensions = acceptedFileTypes.map(x => x.extension)

  const [hasApplied = false, setHasApplied] = useGlobalPageState('has-applied-state')

  const { data: message, mutate: sendApplication, isPending: isLoading, isError, isSuccess } = useMutation({
    mutationFn: handleSendApplication,
    onError: reportError,
    onSuccess: () => {
      setHasApplied(true)
      pushToDataLayer({ event: 'purchase' })
    }
  })

  const hasMessage = messages.includes(message)

  const {
    fetchingScreeningQuestions,
    questionsInitialValues,
    screeningQuestionsWithoutInformation,
    hasScreeningQuestions,
    screeningQuestions
  } = useScreeningQuestions(job)

  const initialValuesRef = React.useRef(createInitialValues(enablePortfolioField))

  const { form, submit } = useForm({
    initialValues: {
      default: initialValuesRef.current.default,
      ...(hasScreeningQuestions && {
        answers: prefillQuestionsInitialValues(questionsInitialValues, initialValuesRef.current.answers),
      }),
      consentPrivacy: false,
      consentCrm: false,
    },
    fields: {
      default: object({
        firstName: [required, maxLength(128)],
        lastName: [required, maxLength(128)],
        email: [required, email, noEmailAccents, maxLength(128)],
        phoneNumber: [required, maxLength(128)],
        link: [linkedInUrl, maxLength(128)],
        ...(enablePortfolioField && { linkPortfolio: [maxLength(128)] }),
        resume: [
          ...(resumeFieldRequired ? [required] : []),
          fileSize(maxFileSize),
          fileExtension(extensions)
        ],
        skillsAmbitions: [maxLength(3600)],
      }),
      ...(hasScreeningQuestions && {
        answers: object(questionsFields(screeningQuestionsWithoutInformation)),
      }),
      consentPrivacy: [required, checked],
      consentCrm: optional,
    },
    onSubmit: handleSubmit
  })

  if (isSuccess && !hasMessage) return (
    <ThankYouMessage firstNameField={form.fields.default.fields.firstName} jobTitle={job.title} />
  )

  return (
    <Base>
      <div ref={formRef} className={styles.heading}>
        <SubheadingSm
          title={__`job-detail-application-form-label`}
          layoutClassName={styles.subheadingLayout}
        />
        <HeadingLg h='3' layoutClassName={styles.headingLayout} {...{ title }} />
      </div>

      <form onSubmit={submit} className={styles.form} onChange={handleCheckout} noValidate>
        {hasScreeningQuestions
          ? (
            <FormFieldsWithSteps
              onScrollToForm={handleScrollToForm}
              {...{ form, extensions, isLoading, screeningQuestions, resumeFieldRequired, enablePortfolioField, fetchingScreeningQuestions }}
            />
          ) : <FormFields onScrollToForm={handleScrollToForm} {...{ form, isLoading, extensions, resumeFieldRequired, enablePortfolioField }} />}

        {isError && <ErrorMessage message={__`application-form-error-message`} />}
        {hasMessage && <Message  {...{ message }} />}
      </form>
    </Base>
  )

  function handleScrollToForm() {
    const top = window.scrollY + formRef?.current.getBoundingClientRect().top - 100
    window.scrollTo(0, top)
  }

  function handleSubmit({ invalid, value }) {
    if (invalid) return
    sendApplication(value)
  }

  async function handleSendApplication(value) {
    const { answers: initialAnswers, consentPrivacy, consentCrm } = value
    const { resume, ...restFormValues } = value.default

    const { files, storageRefs } = await getFilesInfo(resume)

    const { user: { uid } } = await signInAnonymously(getAuth(firebaseApp))

    const database = getDatabase(firebaseApp)
    const currentJobStatus = await getCurrentJobStatus(database)
    if (currentJobStatus && currentJobStatus !== 'PUBLISHED') return unpublishedJobMessage

    const { answers, message } = hasScreeningQuestions
      ? await handleUpdatedScreeningQuesions({ initialQuestions: screeningQuestions, initialAnswers, job, reportError })
      : { answers: initialAnswers }

    if (message) {
      handleScreeningQuestionsChanged(value)
      return message
    }

    if (files) await storeFiles({ uid, files })

    const clientId = await getGoogleAnalyticsId({ ga4Id, identifier: 'client_id' }).catch(reportError)
    const sessionId = await getGoogleAnalyticsId({ ga4Id, identifier: 'session_id' }).catch(reportError)

    const result = await push(ref(database, 'services/application-processing-service/queue'), {
      jobAdId: job.jobAdId,
      jobId: job.jobId,
      uuid: job.uuid,
      userUid: uid,
      formSubmitDate: serverTimestamp(),
      storageRefs,
      isEmployee,
      formValues: {
        consentPrivacy,
        consentCrm,
        ...restFormValues,
        ...(answers && { answers: atsFormValuesData(answers, screeningQuestionsWithoutInformation) })
      },
      ...(utm && { utm }),
      ...(clientId && { clientId }),
      ...(sessionId && { sessionId }),
    })

    if (!clientId || !sessionId) return

    await update(ref(database, `services/application-processing-service/jobInfo/${result.key}`), {
      ...getJobtrackingData(job),
    }).catch(reportError)
  }

  async function getFilesInfo(resume) {
    const resumeFile = resume instanceof File && await resume.arrayBuffer()

    if (!resumeFile?.byteLength)
      return { files: null, storageRefs: null }

    const extension = getExtension(resume.name)

    return {
      files: { resume: { file: resumeFile, extension } },
      storageRefs: { resume: { extension } }
    }
  }

  async function storeFiles({ uid, files }) {
    const storage = getStorage(firebaseApp)

    await Promise.all(
      Object.entries(files).map(
        async ([fieldName, { file, extension }]) => {
          const uploadRef = storageRef(storage, `/uploads/${uid}/${job.uuid}/${fieldName}.${extension}`)
          await uploadBytes(uploadRef, file)
        }
      )
    )
  }

  function handleCheckout() {
    if (!trackedBeginCheckoutRef.current) {
      trackedBeginCheckoutRef.current = true
      pushToDataLayer({ event: 'begin_checkout' })
    }
  }

  async function getCurrentJobStatus(database) {
    const shopJobStatus = (await get(ref(database, `/shopJobs/${job.jobAdId}/status`))).val()
    const officeJobStatus = (await get(ref(database, `/officeJobs/${job.jobAdId}/status`))).val()
    return officeJobStatus ?? shopJobStatus
  }

  function handleScreeningQuestionsChanged(formValues) {
    initialValuesRef.current = formValues
    client.refetchQueries({ queryKey: ['screeningQuestions'] })
  }
}

function questionsFields(questions) {
  return questions.reduce(
    (result, question) => {
      const [field] =  question.fields
      const baseValidation = field.required
        ? field.type === 'CHECKBOX' || field.type === 'MULTI_SELECT'
          ? [required, checked]
          : [required]
        : []

      const extraRules = validationPerFieldType[field.type]
      const validation = baseValidation.concat(extraRules ?? [])

      return { ...result, [question.id]: validation }
    },
    {}
  )
}

function ErrorMessage({ message }) {
  return <div>{message}</div>
}

function Message({ message }) {
  const { __ } = useTranslate()

  return (
    <div>
      {message === unpublishedJobMessage ? <UnpublishedMessage /> : __`${message}`}
    </div>
  )
}

function UnpublishedMessage() {
  const { __ } = useTranslate()
  const { params } = useLocationMatch()

  return (
    <>
      {__`application-form-unpublished-message-start`}
      <LinkUnderline
        href={routeMap.app.jobs.overview({ language: params.language })}
        dataX='link-to-jobsOverview'
      >
        {__`application-form-unpublished-message-link-label`}
      </LinkUnderline>
      {__`application-form-unpublished-message-end`}
    </>
  )
}

function ThankYouMessage({ firstNameField, jobTitle }) {
  const { state: { value: name } } = useFormField(firstNameField)
  const { ref: sizeRef, size } = useElementSize()
  const showScreensaver = useOpenScreensaver()
  const { __ } = useTranslate()

  const thankYouHeading = __({ name })`form-ThankYou-thank-you`
  const succesMessage = __({ title: jobTitle })`form-ThankYou-you-succesfully-submitted`

  return (
    <div className={styles.componentThankYouMessage} data-style-context={cssStyleContext.contextClay}>
      <SubheadingSm title={thankYouHeading} layoutClassName={styles.subheadingLayout} />
      <HeadingLg h='3' title={succesMessage} layoutClassName={styles.headingLayout} />
      <TextSm text={__`form-ThankYou-take-a-deep-breath`} layoutClassName={styles.textLayout} />
      <Button onClick={showScreensaver} dataX='click-to-meditate'>{__`form-thankYou-button-label-take-a-moment`}</Button>

      <div className={styles.breathingCirclesContainer}>
        <div ref={sizeRef} className={styles.breathingCircles}>
          {Boolean(size.width && size.height) && (
            <BreathingCircles width={size.width} height={size.height} />
          )}
        </div>
      </div>
    </div>
  )
}

function Base({ children }) {
  return (
    <div className={styles.componentBase} data-style-context={cssStyleContext.contextGray} data-x='application-form'>
      {children}
    </div>
  )
}

function getQuestionsInitialValues(questions) {
  return Object.fromEntries(
    questions.map(question => {
      const [field] =  question.fields
      return [question.id, field.type === 'CHECKBOX' ? false : '' ]
    })
  )
}

function getExtension(name) { return name.split('.').slice(-1).join() }

function atsFormValuesData(answers, screeningQuestionsWithoutInformation) {
  return Object.fromEntries(
    Object.entries(answers).map(([id, value]) => {
      const question = screeningQuestionsWithoutInformation.find(x => x.id === id)
      const [field] = question.fields

      return [id, {
        id: field.id,
        values: Array.isArray(value) ? value : [value]
      }]
    })
  )
}

function useScreeningQuestions({ uuid, languageData }) {
  const reportError = useReportError()

  /* We don't use isError, because if something is wrong we just want to remove the screening questions.
  Because the api does not give an error if no answers to the screening questions are sent at all,
  even if there are mandatory screening questions associated with the job. */

  const { data, isFetching } = useQuery({
    queryKey: ['screeningQuestions'],
    queryFn: () => fetchScreeningQuestions({ uuid, languageShortCode: languageData.code, reportError }),
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const {
    questionsInitialValues,
    screeningQuestionsWithoutInformation,
    screeningQuestions
  } = React.useMemo(
    () => {
      const screeningQuestions = data?.questions ?? []
      const screeningQuestionsWithoutInformation = questionsWithoutInformation(screeningQuestions)

      return {
        screeningQuestions,
        questionsInitialValues: getQuestionsInitialValues(screeningQuestionsWithoutInformation),
        screeningQuestionsWithoutInformation,
      }
    },
    [data]
  )

  return {
    fetchingScreeningQuestions: isFetching,
    questionsInitialValues,
    screeningQuestions,
    screeningQuestionsWithoutInformation,
    hasScreeningQuestions: Boolean(screeningQuestionsWithoutInformation?.length)
  }
}

function questionsWithoutInformation(screeningQuestions) {
  return screeningQuestions.filter(question => !question.fields.some(x => x.type === 'INFORMATION'))
}

async function fetchScreeningQuestions({ uuid, languageShortCode, reportError }) {
  try {
    const response = await fetch(routeMap.api.v1.screeningQuestions(), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ uuid, languageShortCode })
    })

    const result = response.ok ? await response.json() : []
    return result
  } catch (e) {
    reportError(e)
    return []
  }
}

function getJobtrackingData(job) {
  const x = mapJobForDataLayer(job)

  /**
    * If something changes in candidateTracking, also change it in:
    * createFirebaseRules.js : candidateTracking
    * canidate-tracking-service.js : handleApplicationStatusUpdate
    * pushToDatalayer : mapJobForDataLayer
  */

  return {
    id: x.id,
    jobid: x.jobid,
    refnumber: x.refnumber,
    title: x.title,
    brand: x.brand,
    type: x.type,
    datestart: x.datestart,
    language: x.language,
    experience: x.experience,
    location: {
      country: x.location.country,
      city: x.location.city,
      type: x.location.type,
      postalcode: x.location.postalcode,
      address: x.location.address,
      lat: x.location.lat,
      long: x.location.long,
    },
    role: x.role ?? null,
    department: x.department ?? null,
    typeofemployment: x.typeofemployment ?? null,
    hoursperweekmin: x.hoursperweekmin ?? null,
    hoursperweekmax: x.hoursperweekmax ?? null,
  }
}

/* It may be that while the user is filling out the form the questions have changed in the ATS.
For that reason, we retrieve the questions again and check if the application can be sent to the ATS without problems*/
async function handleUpdatedScreeningQuesions({ initialQuestions, initialAnswers, job, reportError }) {
  const { uuid, languageShortCode } = job

  const result = await fetchScreeningQuestions({ uuid, languageShortCode, reportError })
  const currentQuestions = result?.questions ?? []
  if (!currentQuestions.length) {
    return { answers: initialAnswers } // We continue sending the application to firebase
  }

  const hasNewRequiredField = checkHasNewRequiredFields({ initialQuestions, currentQuestions })
  if (hasNewRequiredField) {
    return { message: 'new-required-field-message', answers: initialAnswers }
  }

  const hasEmptyOptionalFieldChangedToRequired = emptyOptionalFieldsChangedToRequired({ initialQuestions, currentQuestions, initialAnswers, reportError })
  if (hasEmptyOptionalFieldChangedToRequired) {
    return { message: 'optional-field-required-message', answers: initialAnswers }
  }

  /* This must be the last check because this is the only one where we don't rerender */
  const removedFields = getRemovedFields({ initialQuestions, currentQuestions })
  if (removedFields.length) {
    return { answers: removeObsoleteAnswers({ initialAnswers, removedFields }) }
  }

  return { answers: initialAnswers }
}

function removeObsoleteAnswers({ initialAnswers, removedFields }) {
  return Object.fromEntries(
    Object.entries(initialAnswers).filter(([id]) => !removedFields.some(field => field.id === id))
  )
}

function getRemovedFields({ initialQuestions, currentQuestions }) {
  const removedFields = initialQuestions.filter(x => !currentQuestions.find(y => x.id === y.id))
  return removedFields
}


function checkHasNewRequiredFields({ initialQuestions, currentQuestions }) {
  const newQuestions = currentQuestions.filter(x => !initialQuestions.some(y => x.id === y.id))
  return newQuestions.some(field => field.fields.some(x => x.required))
}

function emptyOptionalFieldsChangedToRequired({ initialQuestions, currentQuestions, initialAnswers, reportError }) {
  const optionalFields = initialQuestions.filter(initialQuestion => initialQuestion.fields.every(field => !field.required))

  return optionalFields.some(optionalField => {
    const currentQuestion = currentQuestions.find(({ id }) => optionalField.id === id)
    if (!currentQuestion) {
      reportError(`Something is wrong. Can't find field id ${optionalField.id} in currentQuestion`)
      return false // This should't hadden, so we continue sending the application to firebase
    }
    const hasRequiredField = currentQuestion.fields.some(field => field.required)
    const isRequiredAndEmpty = hasRequiredField && !initialAnswers[optionalField.id]
    return isRequiredAndEmpty
  })
}

function prefillQuestionsInitialValues(questionsInitialValues, previousAnswers) {
  return mapValues(questionsInitialValues, (initialValue, id) => previousAnswers[id] || initialValue)
}

function createInitialValues(enablePortfolioField) {
  return {
    default: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      link: '',
      ...(enablePortfolioField && { linkPortfolio: '' }),
      resume: null,
      skillsAmbitions: '',
    },
    answers: {},
  }
}
